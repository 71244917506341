export function initBlogSearch() {
  if (location.pathname.startsWith('/blog/search')) return;
  const form = document.getElementById('blogSearch');
  if (!form) return;
  const input = form.querySelector('#term');
  const results = form.querySelector('.blog-search-results')

  document.addEventListener('click', (ev) => {
    const { target } = ev;
    if (!target.closest('#blogSearch')) {
      results.classList.add('closed');
    }
  });
  input.addEventListener('focus', () => {
    if (results.innerHTML.length > 0) {
      results.classList.remove('closed');
    }
  });

  input.addEventListener('keyup', async (e) => {
    const { target } = e;
    const { value } = target;
    if (value.length === 0) {
      results.innerHTML = '';
    }
    if (value.trim().length < 3) return;
    const resp = await fetch(`${form.action}?format=json&term=${value}`, {
      method: 'GET',
      headers: {
        "Content-Type": 'application/json'
      },
    });
    const data = await resp.json();
    let htmlMarkup = `<li>We found no matches for: ${value}</li>`;
    if (data.length > 0) {
      htmlMarkup = data.map((row, i) => `
        <li key="row-${i+1}">
          <a href="/blog/${row.slug}">
            ${row.name}
            <span>${row.body}</span>
          </a>
        </li>
      `).join('\n');
    }
    results.innerHTML = htmlMarkup;
  });
}