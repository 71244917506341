import { initBlogSearch } from "../components/blog_search/search";

window.addEventListener('DOMContentLoaded', function() {
  const wrapper = this.document.querySelector('.blog-promoted-posts');
  if (!wrapper) return;
  const backgrounds = [...wrapper.querySelectorAll('.backgrounds img')];
  const posts = [...wrapper.querySelectorAll('.promoted-post')];

  if (backgrounds.length === 1 && !posts.length) return;

  function setPromotedPost(index) {
    backgrounds.forEach((bg) => {
      bg.dataset.active = +bg.dataset.index === index;
    });
    posts.forEach((post) => {
      post.dataset.active = +post.dataset.index === index;
    });
  }

  function switchPromotedPost() {
    const activePost = Number(posts.find((e) => e.dataset.active === 'true')?.dataset?.index) || 0;
    let nextPost = activePost + 1;
    if (activePost === posts.length - 1) {
      nextPost = 0;
    }
    setPromotedPost(nextPost);
  }

  setInterval(() => switchPromotedPost(), 1000 * 15);

  initBlogSearch();

}, { once: true });
